import React, { Suspense, ReactElement, ComponentType, ComponentProps } from 'react'
import { ErrorBoundary } from '@chilipiper/sentry'

type WaitingComponent = <T extends object>(
  Component: ComponentType<T>,
  fallback: ReactElement | string
) => (props: T) => React.JSX.Element

export const WaitingComponent: WaitingComponent = (Component, fallback) => {
  const ComponentWrapper = (props: ComponentProps<typeof Component>) => (
    <ErrorBoundary>
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  )

  return ComponentWrapper
}
